<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <img src="../assets/logo.png">
      <a class="navbar-brand" translate="no">My coaching helper</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" v-if="!isHomePage">
            <router-link to="/" class="nav-link">Accueil</router-link>
          </li>
          <li class="nav-item" v-if="!isDisciplinePage">
            <router-link to="/Disciplines" class="nav-link">Disciplines</router-link>
          </li>
          <li class="nav-item" v-if="!isExercicePage">
            <router-link to="/Exercices" class="nav-link">Exercices</router-link>
          </li>
          <li class="nav-item" v-if="!isMusclePage">
            <router-link to="/Muscles" class="nav-link">Muscles</router-link>
          </li>
          <li class="nav-item" v-if="!isSeancePage">
            <router-link to="/Seance" class="nav-link">Séance</router-link>
          </li>
        </ul>
        <ul class="navbar-nav"> 
          <li class="nav-item" v-if="!isConnected">
            <span class="nav-link connexion-link" @click="showModalConnexion">Connexion</span>
          </li>
          <li class="nav-item" v-else>
            <span class="nav-link connexion-link" @click="logout">Déconnexion</span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div><h1>{{ pageTitle }}</h1></div>
  <ModalConnexion v-if="isShowModalConnexion" @registration="showModalRegistration" @close="closeModal" @connected="onConnected"/>
  <ModalRegistration v-if="isShowModalRegistration" @connexion="showModalConnexion" @close="closeModal"/>


</template>

<script>
import { useRouter } from 'vue-router';
import ModalConnexion from '../components/ModalConnexion.vue';
import ModalRegistration from '../components/ModalRegistration.vue';


export default {
  components: {
    ModalConnexion,
    ModalRegistration,
  },
  name: 'MenuTitre',

  data() {
    return {
      isMenuOpen: false,
      isShowModalConnexion: false,
      isShowModalRegistration: false,
      router: useRouter(),
      isConnected: !!localStorage.getItem("user_id"),
    };
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
  },
  
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    goToHome() {
      this.router.push('/');
    },

    showModalConnexion(){
      this.isShowModalConnexion = true;
      this.isShowModalRegistration = false;
    },

    showModalRegistration(){
      this.isShowModalRegistration = true;
      this.isShowModalConnexion = false;
    },

    closeModal() {
      this.isShowModalRegistration = false;
      this.isShowModalConnexion = false;
    },

    onConnected(){
      this.isConnected = true;
      console.log('connecté :', this.isConnected);
    },

    logout() {
      // Supprimer les informations utilisateur du localStorage
      localStorage.removeItem("user_id");
      localStorage.clear();
      this.isConnected = false;
      console.log('deconnecté :', this.isConnected);

      // Redirection vers la page de connexion ou d'accueil
      this.$router.push("/");
    },

    // Méthode pour gérer les changements dans localStorage
    handleStorageChange(event) {
      if (event.key === "user_id") {
        this.isConnected = !!event.newValue; // Met à jour isConnected
      }
    },

  },

  beforeUnmount() {
    // Nettoie l'écouteur d'événements
    window.removeEventListener("storage", this.handleStorageChange);
  },

  computed: {
    // Vérifier si la route actuelle est la page d'accueil
    isHomePage() {
      return this.$route.path === '/';
    },
    isDisciplinePage() {
      return this.$route.path === '/Disciplines';
    },
    isExercicePage() {
      return this.$route.path === '/Exercices';
    },
    isMusclePage() {
      return this.$route.path === '/Muscles';
    },
    isSeancePage() {
      return this.$route.path === '/Seance';
    },
  },

  mounted() {
    // Surveille les changements dans le localStorage
    window.addEventListener("storage", this.handleStorageChange);
  },
};

</script>

<style scoped>

  img{
    max-height: 50px;
    margin-right: 10px;
  }

  .connexion-link{ 
    cursor: pointer;
  }

</style>