<!-- ModalExercice.vue -->
<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="login-email" class="form-label">Email</label>
          <input type="email" class="form-control" id="login-email" v-model="email" required>
        </div>
        <div class="mb-3">
          <label for="login-password" class="form-label">Mot de passe</label>
          <input type="password" class="form-control" id="login-password" v-model="password" required>
        </div>
        <p v-if="isErrorMessage">{{errorMessage}}</p>
        <button type="submit" class="btn btn-primary w-100">Se connecter</button>
      </form>
      <div>
        <p>Vous n'avez pas de compte ?
          <span class="nav-link connexion-link" @click="showModalRegistation">S'inscrire ici</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  emits: ["registration", "close","connected"],
  data() {
    return {
      email: '',
      password: '',
      isErrorMessage:false,
      errorMessage:"",
    };
  },
  methods: {

    showModalRegistation(){
      this.$emit("registration");
    },

    close() {
        this.$emit("close");
      },

    async login() {
      try {
        const response = await axios.post("https://my-coaching-helper.go.yj.fr/api/connexion.php", {
          email: this.email,
          password: this.password,
        });

        if (response.data.success) {
          // Stocker l'ID utilisateur ou le token
          console.log(response.data.user_id);
          localStorage.setItem("user_id", response.data.user_id);
          console.log('connection reussi');
          this.$emit("connected");
          this.close();
        } else {
          this.isErrorMessage = true;
          this.errorMessage = response.data.message;
        }
      } catch (error) {
        console.error("Erreur réseau :", error.response?.data || error.message);
      }
      
    },


  },
};
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
  }

  span{
    cursor : pointer;
    font-size: 14px;
    color : blue;
  }

</style>