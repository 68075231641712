<!-- ModalExercice.vue -->
<template>
  <div v-if="showModal" class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <p style="color: red;">Attention ce muscle a été associé aux exercices suivants: Veuillez les modifier ou les supprimer</p>
      <ul>
        <li v-for="exercice in exercices" :key="exercice.id">
          {{ exercice.name }}
          <button class="btn btn-light" @click="editExerciceMuscles(exercice.id)" title="Modifier les muscles"><i class="bi bi-pen"></i></button>
          <button class="btn btn-light" @click="removeExercice(exercice.name)" title="Supprimer l'exercice"><i class="bi bi-trash text-danger"></i></button>
          
          <!-- Liste de disciplines avec cases à cocher (affichée seulement en mode modification) -->
          <div v-if="editExerciceId === exercice.id">
            <div class="checkbox-container" >
              <div v-for="muscle in allMusclesWithoutMain" :key="muscle.id" class="checkbox-item">
                <label>
                  <input
                    type="checkbox"
                    :value="muscle.name"
                    v-model="selectedMuscles"
                  />
                  {{ muscle.name }}
                </label>
              </div>
            </div>
            <div>
              <!-- Bouton Valider pour mettre à jour -->
              <button class="btn btn-primary" @click="updateExerciceMuscles(exercice.name)">Valider</button>
            </div>
          </div>
        </li>
      </ul>

      <button class="btn btn-primary" @click="close">Fermer</button>
    </div>
  </div>
</template>

<script>

//import {fetchAllMuscles, deleteExercice, associateExerciceMuscles} from'../Database.js';
import axios from 'axios';


  export default {
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
      exercices: {
        type: Array,
        required: true,
      },
      muscleToRemove : String,
    },
    emits: ["close", "ChangeDone"],
    
    data() {

      return {
        allMusclesWithoutMain: [],
        editExerciceId: null,
        selectedMuscles: [],  
      };
    },

    methods: {
      close() {
        this.$emit("close");
      },
      
      editExerciceMuscles(exerciceId) {
        this.editExerciceId = exerciceId;
      },
      
      async removeExercice(exercice) {
        //await deleteExercice(exercice);
        await axios.delete("https://my-coaching-helper.go.yj.fr/api/deleteExerciceForUsers.php",{
            data :{
              name: exercice,
              users_id : localStorage.getItem("user_id"),
            }
          });
        this.$emit("ChangeDone");
      },

      async updateExerciceMuscles(exerciceName){
      //await associateExerciceMuscles(exerciceName, this.selectedMuscles);
      await axios.post("https://my-coaching-helper.go.yj.fr/api/associateExerciceMuscle.php", {
        user_id: localStorage.getItem("user_id"),
        exerciceName: exerciceName,
        muscleNames: this.selectedMuscles,
      });
      this.selectedMuscles = [];
      this.$emit("ChangeDone");
      },
    },

    async mounted() {
    //const allMuscles = await fetchAllMuscles();
    const response = await axios.get("https://my-coaching-helper.go.yj.fr/api/fetchAllMusclesByUsers.php",{
        params: {
          users_id: localStorage.getItem("user_id"),
        }
      });
      if (response.data.message) {
        // Si un message est présent, cela signifie qu'aucune discipline n'a été trouvée
        this.infosMessage = response.data.message;
        this.showModalMessage();
        this.muscles = [];  // Assigner un tableau vide ou gérer selon vos besoins
      } 
      else {
        // Si des disciplines sont retournées
        const allMuscles = response.data;  // Assignation des disciplines à l'état du composant
        this.allMusclesWithoutMain = allMuscles.filter(muscle => muscle.name !== this.muscleToRemove);
      }
  }
  };
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
  }

  .checkbox-item {
    flex: 1 1 150px; /* Minimum de 150px, mais peut s'étendre */
    display: flex;
    align-items: center;
  }

  .checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Espace entre les checkboxes */
    overflow-y: auto;
    max-height: 200px;
  }
</style>