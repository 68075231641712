
<template>
  <div>
    <MenuTitre :pageTitle="title" />
    <!-- Contenu principal -->
    <div class="container-fluid">
      <div class="row content">
        <div class="col-md-2"></div>
        <div class="col-md-8 col-sm-12">
          <button 
            class="btn btn-primary" 
            @click="showRandomProgrammeFilter"
            title="Génère une séance avec des exercices aléatoire qui correspondent à vos critères">
            Séance aléatoire
          </button>
          <button 
            class="btn btn-primary" 
            @click="showManualProgrammeFilter"
            title="Affiche tous les exercices qui correspondent à vos critères">
            Séance manuelle
          </button>
          <ProgrammeFilter v-if="filterSection" 
            :manualSeance="isManual" 
            :showFilter="showFilter"
            :shouldReset="resetFilter"
            @closefilter="onClosefilter"
            @isGenerate="hideFilter"
            @showFilter="onShowFilter"
            />
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import MenuTitre from '../components/MenuTitre.vue';
  import ProgrammeFilter from '../components/ProgrammeFilter.vue';

  export default {
    name: 'SeancePage',
    components:{
      MenuTitre,
      ProgrammeFilter,
    },

    data() {
      return {
        title: '',

        filterSection : false,
        isManual : false,
        showFilter : false,
        resetFilter : false,
      };
    },

    methods: {

      onClosefilter(){
        this.filterSection = false;
      },

      onShowFilter(data){
        this.resetFilter = false;
        this.isManual = data;
        this.showFilterProgramme();
        
      },

      showManualProgrammeFilter(){
        this.resetFilter = true;
        this.isManual = true;
        this. showFilterProgramme();
      },

      showRandomProgrammeFilter(){
        this.resetFilter = true;
        this.isManual = false;
        this. showFilterProgramme();
      },

      showFilterProgramme() {
        this.filterSection = true;
        this.showFilter = true;
      },

      hideFilter(){
        this.showFilter = false;
        this.resetFilter = false;
      },
    },
  }

</script>

<style scoped>

button {
  margin: 4px 2px;
}
.content{
  margin-left: 10px;
  margin-right: 10px;
}
</style>