
<template>
  <div>
    <MenuTitre :pageTitle="title" />
    <!-- Contenu principal -->
    <div class="container-fluid">
      <div class="row content">
        <div class="col-md-2"></div>
        <div class="col-md-8 col-sm-12 homeSentence">
          <h2>Bienvenue sur My Coaching Helper</h2>
          <h4>votre outil pour créer des séances de sport en un clin d'œil !</h4>
          <p>
            <ul class="list-unstyled">
              <li>Commencez par personnaliser vos disciplines et vos exercices</li>
              <li>Générez vos séances personnelles ou générez des séances aléatoires</li>
            </ul>
          </p>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import MenuTitre from '../components/MenuTitre.vue';

  export default {
    name: 'SeancePage',
    components:{
      MenuTitre,
    },

    data() {
      return {
        title: '',
      };
    },
  }

</script>

<style scoped>

.content{
  margin-left: 10px;
  margin-right: 10px;
}

.homeSentence{
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Ajouter une bordure légère pour délimiter le cadre */
  border-radius: 8px; /* Ajouter des coins arrondis */
}

</style>