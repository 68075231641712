<template>
  <!-- Fenêtre modale -->
  <div class="modal-overlay">

    <div class="modal-content">
      <div v-if='errorMessage'>
        <p style="color: red;">{{ errorMessage }}</p>
      </div>

       <!-- Section qui s'affiche en fonction de la variable isSectionVisible -->
      <div v-if="isNewExerciceSectionVisible" class="section-content">
        <h5>Entrez le nom de l'exercice</h5>
        <div>
          <input v-model="newExercice" placeholder="Nom de l'exercice" @keyup.enter="showExerciceDisciplineSection"/>
        </div>
        <div>
          <button class="btn btn-primary" @click="showExerciceDisciplineSection">Suivant</button>
          <button class="btn btn-primary" @click="cancel">Annuler</button>
        </div>
      </div>
      
      <!-- Section qui s'affiche en fonction de la variable isSectionVisible -->
      <div v-if="isExerciceDisciplineSectionVisible" class="section-content">
        <h5>Sélectionnez les disciplines associées</h5>
        <div class="checkbox-container">
          <div translate="no" v-for="(item, index) in disciplines" :key="index" class="checkbox-item">
            <label>
              <input type="checkbox" :value="item.name" v-model="disciplineSelected">
              {{ item.name }}
            </label>
          </div>
        </div>
        <div> 
          <button class="btn btn-primary" @click="showNewExerciceSection">Précédent</button>
          <button class="btn btn-primary" @click="showExerciceMuscleSection">Suivant</button>
          <button class="btn btn-primary" @click="cancel">Annuler</button>
        </div>
      </div>

      <!-- Section qui s'affiche en fonction de la variable isSectionVisible -->
      <div v-if="isExerciceMusclesSectionVisible" class="section-content">
        <h5>Sélectionnez les muscles associés</h5>
        <div class="checkbox-container">
          <div translate="no" v-for="(item, index) in muscles" :key="index" class="checkbox-item">
            <label>
              <input type="checkbox" :value="item.name" v-model="muscleSelected">
              {{ item.name }}
            </label>
          </div>
        </div>
        <div>
          <button class="btn btn-primary" @click="showExerciceDisciplineSection">Précédent</button>
          <button class="btn btn-primary" @click="showExerciceDifficulty">Suivant</button>
          <button class="btn btn-primary" @click="cancel">Annuler</button>
        </div>  
      </div>
      <div v-if="isExerciceDifficulty" class="section-content">
        <h5>Sélectionnez la difficulté de l'exercice</h5>
        <div class="rating">
          <i
            v-for="n in 5"
            :key="n"
            class="star bi"
            :class="n <= difficulty ? 'bi-star-fill selected' : 'bi-star'"
            @click="selectRating(n)"
          ></i>
        </div>
        <button class="btn btn-primary" @click="showExerciceMuscleSection">Précédent</button>
        <button class="btn btn-primary" @click="addExercice">Ajouter</button>
        <button class="btn btn-primary" @click="cancel">Annuler</button>
      </div>

    </div>

  </div>
</template>

<script>
//import {insertExercice, fetchAllDisciplines, associateMuscleExerciceDisciplines,fetchAllMuscles } from'../Database.js';
import axios from 'axios';

export default {
  name : 'ModalAddExercice',

  data() {
    return {

      muscleSelected : [],
      disciplineSelected: [],
      newExercice: '',
      disciplines: [],       
      muscles: [],
      difficulty : 0,

      isNewExerciceSectionVisible: true,
      isExerciceDisciplineSectionVisible: false,
      isExerciceMusclesSectionVisible: false,
      isExerciceDifficulty : false,

      errorMessage:'',
    };
  },
/*
  watch: {
    oldName(newVal){
      this.oldExercice = newVal;
      this.newExercice = newVal;
    },

    oldMuscles(newVal){
      this.muscleSelected = newVal;
    },

    oldDiscipline(newVal){
      this.disciplineSelected = newVal
    },

    oldDifficulty(newVal){
      this.difficulty = newVal
    },
  },*/

  methods: {

    checkData(){
      if(!this.newExercice){
        this.errorMessage="Veuillez insérer un nom d'exercice";
        this.showNewExerciceSection();
        return false;
      }
      else if(this.disciplineSelected.length == 0){
        this.errorMessage="Veuillez sélectionner au moins une discipline";
        this.showExerciceDisciplineSection();
        return false;
      }
      else if(this.muscleSelected.length == 0){
        this.errorMessage="Veuillez sélectionner au moins un muscle";
        this.showExerciceMuscleSection();
        return false;
      }
      else if(this.difficulty == 0){
        this.errorMessage="Veuillez définir la difficulté";
        this.showExerciceDifficulty();
        return false;
      }
      else{
        return true;
      }
    },

    async addExercice() {
      if (!this.checkData())return;
      if (this.newExercice) {
        try{
          // Ajouter la nouvelle discipline dans la base de données
          //await insertExercice(this.newExercice, this.difficulty);
          await axios.post("https://my-coaching-helper.go.yj.fr/api/insertExercice.php",
            {
              name: this.newExercice.trim(), // Envoyer le nom du muscle
              difficulty: this.difficulty,
              users_id : localStorage.getItem("user_id"),
            });
          
          await this.associateMuscleExerciceDisciplines(this.newExercice, this.muscleSelected, this.disciplineSelected);

          // Recharger les disciplines après l'ajout
          this.$emit('exerciceAdded');
          this.reinitialise();
        }
        catch(error){
          this.errorMessage=error.message;
          this.newExercice = "";
          this.showNewExerciceSection();
        } 
      }
    },

    async associateMuscleExerciceDisciplines(exerciceName, muscles, disciplines){
      await axios.post("https://my-coaching-helper.go.yj.fr/api/associateExerciceDiscipline.php", {
            user_id: localStorage.getItem("user_id"),
            exerciceName: exerciceName,
            disciplineNames:disciplines,
          });

      await axios.post("https://my-coaching-helper.go.yj.fr/api/associateExerciceMuscle.php", {
        user_id: localStorage.getItem("user_id"),
        exerciceName: exerciceName,
        muscleNames: muscles,
      });

    },

    cancel(){
      this.$emit('cancel');
      this.reinitialise();
    },

    reinitialise(){   
        // Réinitialiser le champ d'entrée
        this.newExercice = '';
        this.muscleSelected = [];
        this.disciplineSelected = [];
        this.difficulty = 0;
        this.errorMessage ="";
        this.isExerciceDisciplineSectionVisible = false; // Inverser la visibilité de la section
        this.isNewExerciceSectionVisible = true;
        this.isExerciceMusclesSectionVisible =false;
        this.isExerciceDifficulty =false;
    },

    async loadDiscipline() {
      try {
        //this.disciplines = await fetchAllDisciplines(); // Récupérer toutes les disciplines

        const response = await axios.get("https://my-coaching-helper.go.yj.fr/api/fetchAllDisciplinesByUsers.php",{
        params: {
          users_id: localStorage.getItem("user_id"),
        }
      });

      if (response.data.message) {
        // Si un message est présent, cela signifie qu'aucune discipline n'a été trouvée
        this.infosMessage = response.data.message;
        this.showModalMessage();
        this.disciplines = [];  // Assigner un tableau vide ou gérer selon vos besoins
      } 
      else {
        // Si des disciplines sont retournées
        this.disciplines = response.data;  // Assignation des disciplines à l'état du composant
      }
      } catch (error) {
        console.error('Erreur lors de la récupération des options :', error);
      }
    },

    async loadMuscle() {
      try {
        //this.muscles = await fetchAllMuscles(); // Récupérer toutes les disciplines
        const response = await axios.get("https://my-coaching-helper.go.yj.fr/api/fetchAllMusclesByUsers.php",{
        params: {
          users_id: localStorage.getItem("user_id"),
        }
      });
      if (response.data.message) {
        // Si un message est présent, cela signifie qu'aucune discipline n'a été trouvée
        this.infosMessage = response.data.message;
        this.showModalMessage();
        this.muscles = [];  // Assigner un tableau vide ou gérer selon vos besoins
      } 
      else {
        // Si des disciplines sont retournées
        this.muscles = response.data;  // Assignation des disciplines à l'état du composant
      }
      } catch (error) {
        console.error('Erreur lors de la récupération des options :', error);
      }
    },

    showNewExerciceSection() {
      this.isNewExerciceSectionVisible = true; // Inverser la visibilité de la section
      this.isExerciceDisciplineSectionVisible = false;
      this.isExerciceMusclesSectionVisible = false;
      this.isExerciceDifficulty = false;
    },

    showExerciceDisciplineSection() {
      this.isExerciceDisciplineSectionVisible = true; // Inverser la visibilité de la section
      this.isNewExerciceSectionVisible = false;
      this.isExerciceMusclesSectionVisible = false;
      this.isExerciceDifficulty = false;
    },

    showExerciceMuscleSection() {
      this.isExerciceMusclesSectionVisible = true; // Inverser la visibilité de la section
      this.isExerciceDisciplineSectionVisible = false;
      this.isNewExerciceSectionVisible = false;
      this.isExerciceDifficulty = false;
    },

    showExerciceDifficulty(){
      this.isExerciceMusclesSectionVisible =false;
      this.isExerciceDifficulty =true;
      this.isNewExerciceSectionVisible = false;
      this.isExerciceDisciplineSectionVisible = false;

    },

    selectRating(rating) {
        this.difficulty = rating;
    },
  },

  async mounted() {
    // Charger les disciplines lorsqu'on monte le composant
    await this.loadDiscipline();
    await this.loadMuscle();
  },
}

</script>

<style scoped>


.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Espace entre les checkboxes */
  overflow-y: auto;
  max-height: 200px;
}

/* Chaque checkbox-item occupe une partie de la ligne */
.checkbox-item {
  flex: 1 1 150px; /* Minimum de 150px, mais peut s'étendre */
  display: flex;
  align-items: center;
}

/* Rendre les checkboxes plus visibles sur petits écrans */
@media (max-width: 576px) {
  .checkbox-item {
    flex: 1 1 100%; /* Sur petits écrans, chaque checkbox occupe toute la largeur */
  }
}

.rating .star {
  font-size: 2rem;
  color: gray;
  cursor: pointer;
}
.rating .star.selected {
  color: gold;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

/* Style pour le contenu de la modale */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 80%;
  text-align: center;
  position: relative;
}

/* Style du bouton de fermeture (croix) */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

button{
  position: sticky;      /* Fixer la position */
  top: 0;                /* Fixer le thead au sommet */
  z-index: 3;            /* Assurer que le thead est au-dessus des autres éléments */
  margin: 4px 2px;
}

</style>

