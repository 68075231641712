
<!-- ModalExercice.vue -->
<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <form @submit.prevent="validateForm">
        <div class="mb-3">
          <label for="login-email" class="form-label">Email</label>
          <input type="email" class="form-control" id="login-email" v-model="email" required>
          <span v-if="errors.email" class="error-text">{{ errors.email }}</span>
        </div>
        <div class="mb-3">
          <label for="login-password" class="form-label">Mot de passe</label>
          <input type="password" class="form-control" id="login-password" v-model="password" required>
          <span v-if="errors.password" class="error-text">{{ errors.password }}</span>
        </div>
        <button type="submit" class="btn btn-primary w-100">S'inscrire</button>
      </form>
      <div>
        <p>Vous avez déjà un compte ? 
          <span class="nav-link connexion-link" @click="showModalConnexion">Se connecter ici</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';


export default {
  emits:["connexion","close"],
  data() {
    return {
      email: '',
      password: '',
      errors: {email: '',password: ''},
    };
  },
  methods: {
    showModalConnexion(){
      this.$emit("connexion");
    },

    close() {
        this.$emit("close");
      },

      async validateForm() {
      let isValid = true;
      
     // Validation email avec une expression régulière
     const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!this.email || !emailRegex.test(this.email)) {
        this.errors.email = 'L\'email est invalide';
        isValid = false;
      } else {
        this.errors.email = '';
      }

      // Validation mot de passe
      if (this.password.length < 6) {
        this.errors.password = 'Le mot de passe doit contenir au moins 6 caractères';
        isValid = false;
      } else {
        this.errors.password = '';
      }

      if (isValid){
        await this.register();
      }
    },

    async register(){
      try {
        await axios.post("https://my-coaching-helper.go.yj.fr/api/inscription.php", {
          email: this.email.trim(),
          password: this.password,
        });
        this.close();
      } catch (error) {
        console.error("Erreur lors de l'inscription :", error.response?.data || error.message);
      }
    },  
  },
};
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 90%;
  }

  span{
    cursor : pointer;
    font-size: 14px;
    color : blue;
  }
  .error-text {
  color: red;
  font-size: 12px;
}

</style>