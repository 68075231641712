<template>
  <div>
    <MenuTitre :pageTitle="title" />
  </div>
  <div v-if="modalMessageIsShow">
    <ModalMessage @messagePrinted="hideModalMessage"
      :infosMessage="infosMessage"/>
  </div>

  <div>
    <!-- Utilisation de la modale -->
    <ModalDeleteMuscle
      v-if="showModal"
      :showModal="showModal"
      :exercices="exerciceNames"
      :muscleToRemove = muscleToRemove
      @close="closeModal"
      @ChangeDone="onChangeDone"
    />
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="scrollable-table col-md-8 col-sm-12 " ref="muscleTableContainer">
        <table class="table">
          <thead>
            <tr>
              <th>Muscle</th>
              <th>Partie du corps</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="muscle in muscles" :key="muscle.id">
              <td>{{ muscle.name }}</td>
              <td>{{ muscle.bodypart }}</td>
              <td><button class="btn btn-light" @click="removeMuscle(muscle.name)" title="Supprimer un muscle"><i class="bi bi-trash text-danger"></i></button></td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="errorMessage">
              <td colspan="3"><p style="color: red;">{{ errorMessage }}</p></td>
            </tr>
            <tr>
              <td colspan="2" class="text-end">
                <div class="form-group">
                  <input class="new-muscle mb-2" v-model="newMuscle" title="Nom du muscle" placeholder="Insérez le nom du muscle" @keyup.enter="addMuscle"/>
                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" v-model="bodypartSelected" name="bodypart" value="Haut du corps">
                      Haut du corps
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" v-model="bodypartSelected" name="bodypart" value="Bas du corps">
                      Bas du corps
                    </label>
                  </div>
                </div>
              </td>
              <td>
                <button class="btn btn-light" @click="addMuscle" title="Ajouter un muscle"><i class="bi bi-plus text-success"></i></button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      </div>
      <div class="col-md-2"></div>
    </div>
</template>

<script>

import ModalDeleteMuscle from '../components/ModalDeleteMuscle.vue';
import MenuTitre from '../components/MenuTitre.vue';
import ModalMessage from '../components/ModalMessage.vue';
//import { insertMuscle, fetchAllMuscles, deleteMuscle, getExercicesByMuscles } from'../Database.js';
import axios from 'axios';

export default {
  components: {
    MenuTitre,
    ModalMessage,
    ModalDeleteMuscle,
  },
  data() {
    return {
      newMuscle: '',  // Stocke le nom du nouveau muscle à ajouter
      muscles: [], //Stocke la liste de tous les muscles 
      title: 'Muscles',  // Liste des disciplines récupérées de la base de données
      bodypartSelected:"",  // valeur du bouton radio partie du corps  
      errorMessage : "", // message d'erreur du contrôle des données

      modalMessageIsShow: false, // etat de la fenetre d'information
      infosMessage:"", // message d'information lors de la suppresion ou de l'ajout d'un muscle

      showModal : false, // etat de la fenetre lors de la suppression d'un muscle 
      exerciceNames: [], // listes des noms de tous les exercices liés un muscle
      muscleToRemove : "", // nom du muscle à supprimer
    };
  },
  methods: {

    // fermeture de la fenetre de suppresion d'un muscle
    closeModal() {
      this.showModal = false; // changement de l'etat de la fenetre de suppresion d'un muscle
    },

    // lors d'un changement dans la fenetre de suppresion d'un muscle
    onChangeDone() {
      this.showModal = false; // changement de l'etat de la fenetre 
      this.removeMuscle(this.muscleToRemove); // appel de la fonction de suppression du muscle
    },

    //  fermeture de la fenetre d'information
    hideModalMessage(){
      this.modalMessageIsShow = false; // changement de l'etat de la fenetre
      this.infosMessage =""; // effacer le message d'information pour un prochaine utilisation
    },

    // affichage de la fentre d'information 
    showModalMessage(){
      this.modalMessageIsShow = true; // changement de l'etat de la fenetre
      // appel de la fonction de fermeture de la fenetre au bout de 1secondes
      setTimeout(()=>{
        this.hideModalMessage();
      },1000);
    },
/*
    async loadMuscles() {
      // Récupérer toutes les disciplines depuis la base de données
      this.muscles = await fetchAllMuscles();
    },
*/
    async loadMuscles() {
      // Récupérer toutes les disciplines depuis la base de données
      // this.disciplines = await fetchAllDisciplines();
      const response = await axios.get("https://my-coaching-helper.go.yj.fr/api/fetchAllMusclesByUsers.php",{
        params: {
          users_id: localStorage.getItem("user_id"),
        }
      });
      if (response.data.message) {
        // Si un message est présent, cela signifie qu'aucune discipline n'a été trouvée
        this.infosMessage = response.data.message;
        this.showModalMessage();
        this.muscles = [];  // Assigner un tableau vide ou gérer selon vos besoins
      } 
      else {
        // Si des disciplines sont retournées
        this.muscles = response.data;  // Assignation des disciplines à l'état du composant
      }
      
    },

    // verification des données entrée par l'utilisateur
    validateMuscleData(name, bodypart){
      if (!name.trim()) return "Veuillez entrer le nom du muscle."; // verification que le champs nom muscle soit non nul
      if (!bodypart) return "Veuillez sélectionner la partie du corps."; // verification qu'une partie du corps soit selectionné via les boiuton radio
      if (!/^[a-zA-Zéèàç\- \d]+$/.test(name)) return "Nom invalide, il ne peut contenir de caractères spéciaux."; // vérification que le nom muscle ne contienne pas de caractères spéciaux
      return null; // Aucune erreur
    },

    // ajout du muscle
    async addMuscle() {
      // appel de la fonction de validation des données et affichage du message d'erreur si erreur 
      const validationError = this.validateMuscleData(this.newMuscle, this.bodypartSelected);
      if (validationError) {
          this.errorMessage = validationError;
          return;
      }
      // tentative d'ajout du nouveau muscle dans la base de donnée 
      try {
          //await insertMuscle(this.newMuscle.trim(), this.bodypartSelected); // insertion du muscle dans la base de donnée
          await axios.post("https://my-coaching-helper.go.yj.fr/api/insertMuscle.php",
            {
              name: this.newMuscle.trim(), // Envoyer le nom du muscle
              bodypart :this.bodypartSelected,
              users_id : localStorage.getItem("user_id"),
            });
          await this.loadMuscles(); // rechagement de tous les muscles pour afficher le nouveau 
          this.infosMessage = "Muscle ajouté avec succès"; // initialisation du message d'information
          this.showModalMessage(); // affichage du message d'information
          // reinitialisation du champs d'entrée et des boutons radio de l'ajout d'un nouveau muscle 
          this.newMuscle = "";
          this.bodypartSelected = "";
          this.$refs.muscleTableContainer.scrollTop = this.$refs.muscleTableContainer.scrollHeight;// montrer à l'utilisateur
          // l'empplacement du dernier muscle ajouter dans le tableau
      } 
      //interception d'erreur lors de l'ejout dans la base de donnée
      catch (error) {
        this.errorMessage=error.message; // affichage de l'erreur à l'utilisateur
        this.newMuscle = ""; // réinitialisation du nom du muscle
      }
    },

    // suppression d'un muscle
    async removeMuscle(name) {
      // verification de l'existence d'exercice lié au muscle à supprimer
      //const exerciceList = await getExercicesByMuscles(name); // stocker les exercices lier au muscle "name"
      const response = await axios.get("https://my-coaching-helper.go.yj.fr/api/getExercicesByMuscle.php",{
        params :{
          name: name,
          users_id : localStorage.getItem("user_id"),
        }
      });
      if(!response.data.message){
        var exerciceList = response.data;
      }

      this.muscleToRemove = name; // enregistrer le nom du muscle à supprimer
      console.log(exerciceList);
      if (exerciceList.length > 0){ // si la liste des exercices à au moins un element
        this.exerciceNames = exerciceList; // on stock ces element dans la liste des exercice lier au muscle
        this.showModal = true; // affichage de la fentre de suppression du muscle
      }else{
        // tentative de suppression du muscle dans la base de donnée
        try {
          // Supprimer la discipline de la base de données
          //await deleteMuscle(name);
          await axios.delete("https://my-coaching-helper.go.yj.fr/api/deleteMuscleForUsers.php",{
            data :{
              name: name,
              users_id : localStorage.getItem("user_id"),
            }
          });
          // Recharger les disciplines après la suppression
          await this.loadMuscles();
          this.infosMessage ="Muscle supprimé avec succès"; // initialiser le messages d'information
          this.showModalMessage();// affciher la fenetre d'information
        } 
        // interception d'erreur lors de la supression du muslce dans la base de donnée
        catch (error) {
          console.error('Echec lors de la supression du muscle', error);
        }
        this.muscleToRemove = "";// réinitialisation du muscle à supprimer
      }
    }
  },

  async mounted() {
    // Charger les disciplines lorsqu'on monte le composant
    await this.loadMuscles();
  },
}

</script>

<style scoped>

.scrollable-table {
  max-width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  border: 1px solid #ccc; /* Ajouter une bordure légère pour délimiter le cadre */
  border-radius: 8px; /* Ajouter des coins arrondis */
}

thead{
  top:0;
}

.table thead, tfoot {
  position: sticky;
  z-index: 2;            /* Maintenir au-dessus des lignes du tbody */
}

tfoot {
  bottom: 0;
}

.new-muscle{
  max-width: 80%;
  margin-right: 10px;
}

button {
  border: none;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 6px;
}

td {
  vertical-align: middle;
}

.row{
  margin-left: 10px;
  margin-right: 10px;
}

</style>
